import React from "react"
import styles from "./showroom-booking.module.scss"

export default class PortfolioSingleTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactNumber: "",
      emailSent: false,
      name: "",
      postcode: "",
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  closeModal() {
    this.props.closeModal()
  }

  checkValid(val) {
    return val !== ""
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  async handleSubmit(event) {
    event.preventDefault()

    const fetchResponse = await fetch(
      "https://taqoxb0ln3.execute-api.eu-west-1.amazonaws.com/dev/book-showroom",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state),
      }
    )

    if (fetchResponse && fetchResponse.status === 200) {
      this.setState({
        emailSent: true,
      })
    }
  }

  isNotEmpty(field) {
    return field !== ""
  }

  formIsValid() {
    return (
      this.isNotEmpty(this.state.name) &&
      this.isNotEmpty(this.state.contactNumber) &&
      this.isNotEmpty(this.state.postcode)
    )
  }

  render() {
    return (
      <div
        className={`modal-container showroomBooking ${
          this.props.isPopupDismissed ? "modal-container hide" : ""
        }`}
      >
        <div className="modal">
          <div
            className="modal-close"
            onClick={() => {
              this.closeModal()
            }}
          >
            Close
          </div>
          <div className={`clearfix ${styles.showroomBooking}`}>
            <div className={styles.wrapper}>
              <div className={styles.intro}>
                <p className={styles.p1}>Book a Private showroom appointment</p>
                <p className={`${styles.p2} ${styles.stroke}`}>
                  We are open by private appointment only for sole use of our
                  stunning Meanwood Road showroom
                </p>
                <p className={styles.p3}>
                  If you would like to discuss a new project or your home
                  renovation needs, please select a topic related to your
                  inquiry, complete our contact form and we will call you back
                  to arrange a showroom appointment. Please note: Your personal
                  details are
                  <em> not</em> shared.
                </p>
              </div>
              <div className={styles.action}>
                <div className={styles.box}>
                  <p className={styles.p4}>
                    Please select a topic you would like to discuss in detail
                  </p>
                  <div className={styles.p5}>
                    <label htmlFor="rb1">Kitchen &amp; Bathroom</label>
                    <input
                      name="topic"
                      type="radio"
                      value="Kitchen Bathroom"
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="rb2">Fires / Stoves / Heating</label>
                    <input
                      name="topic"
                      type="radio"
                      value="Fires / Stoves / Heating"
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="rb3">Kitchen only</label>
                    <input
                      name="topic"
                      type="radio"
                      value="Kitchen only"
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="rb4">All Services</label>
                    <input
                      name="topic"
                      type="radio"
                      value="All Services"
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor="rb5">
                      Project / Building Works with either Kitchen Bathrooms /
                      Fires / Heating
                    </label>
                    <input
                      name="topic"
                      type="radio"
                      value="Project / Building Works with either Kitchen Bathrooms / Fires / Heating"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className={styles.p6}>
                    <form onSubmit={this.handleSubmit}>
                      <input
                        autoComplete="off"
                        name="heroPassword"
                        style={{ display: "none" }}
                        tabIndex="-1"
                        type="text"
                        onChange={this.handleInputChange}
                      />
                      <div className={styles.formGroup}>
                        <input
                          name="name"
                          placeholder="Name"
                          tabIndex="1"
                          type="text"
                          onChange={this.handleInputChange}
                          className={
                            this.isNotEmpty(this.state.name)
                              ? styles.valid
                              : styles.invalid
                          }
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <input
                          name="contactNumber"
                          placeholder="Contact Number"
                          tabIndex="1"
                          type="text"
                          onChange={this.handleInputChange}
                          className={
                            this.isNotEmpty(this.state.contactNumber)
                              ? styles.valid
                              : styles.invalid
                          }
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <input
                          name="postcode"
                          placeholder="Postcode"
                          tabIndex="1"
                          type="text"
                          onChange={this.handleInputChange}
                          className={
                            this.isNotEmpty(this.state.postcode)
                              ? styles.valid
                              : styles.invalid
                          }
                        />
                      </div>
                      <div className={`${styles.formGroup} ${styles.submit}`}>
                        <div
                          className={`${styles.p3} ${styles.thanks} ${
                            !this.state.emailSent ? styles.emailSent : ""
                          }`}
                        >
                          Thank you, we will be in touch to arrange your
                          showroom appointment
                        </div>
                        <input
                          type="submit"
                          className={`general-button jaffa ${
                            styles.generalButton
                          } ${this.state.emailSent ? styles.emailSent : ""}`}
                          value="Send"
                          disabled={
                            this.formIsValid() ? styles.valid : styles.invalid
                          }
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
