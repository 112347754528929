import { Link } from "gatsby"
import React from "react"
import styles from "./primary-nav.module.scss"

export default () => (
  <ul className={styles.list}>
    <li>
      <Link to="/">Home</Link>
    </li>
    <li>
      <Link to="/products">Our Products</Link>
    </li>
    <li>
      <Link to="/services">Our Services</Link>
    </li>
    <li>
      <Link to="/brands">Our Brands</Link>
    </li>
    <li>
      <Link to="/showroom">Showroom</Link>
    </li>
    <li>
      <Link to="/contact">Contact us</Link>
    </li>
    <li>
      <Link to="/meet-the-team">Meet the Team</Link>
    </li>
    <li>
      <Link to="/how-we-work">How we work</Link>
    </li>
    {/* <li>
      <Link to="/blogs">Our Blog</Link>
    </li> */}
    <li>
      <Link to="/architects-contracts">Architects &amp; Contracts</Link>
    </li>
    <li>
      <Link to="/installation">Installation</Link>
    </li>
    <li>
      <Link to="/portfolio">Portfolio</Link>
    </li>
  </ul>
)

// TODO Investigate transition links and preloading content
// TODO Underline selected page
