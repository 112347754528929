import React from "react"
import IconCalendar from "../images/icon-calendar.inline.svg"
import styles from "./button-book-showroom.module.scss"

export default class ButtonBookShowroom extends React.Component {
  openModal() {
    this.props.openModal()
  }
  render() {
    return (
      <div
        onClick={() => {
          this.openModal()
        }}
        className={`general-button jaffa icon icon-calendar ${styles.book}`}
      >
        Book your showroom appointment today
        <IconCalendar></IconCalendar>
      </div>
    )
  }
}
