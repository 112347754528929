import React from "react"

import FacebookLogo from "../images/facebook.inline.svg"
import InstagramLogo from "../images/instagram.inline.svg"
import TwitterLogo from "../images/twitter.inline.svg"
import footerStyles from "./footer.module.scss"

export default () => (
  <footer className={footerStyles.footer}>
    <div className={footerStyles.contact}>
      <p>Contact Us</p>
      <p>BMF Creative Interiors</p>
      <p>248 Meanwood Road, Leeds, LS7 2HZ</p>
      <p>
        T: <a href="tel:0113 266 0096">0113 266 0096</a> E:
        <a href="mailto:info@bmfonline.co.uk">info@bmfonline.co.uk</a>
      </p>
    </div>
    <div className={footerStyles.socialLinks}>
      <ul>
        <li>
          <a
            href="https://twitter.com/bmfonline?lang=en"
            aria-label="Visit our Twitter"
          >
            <TwitterLogo />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/bmf_creative_interiors"
            aria-label="Visit out Instagram"
          >
            <InstagramLogo />
          </a>
        </li>
        <li>
          <a
            href="https://en-gb.facebook.com/bmfonline"
            aria-label="Visit our Facebook"
          >
            <FacebookLogo />
          </a>
        </li>
      </ul>
    </div>
  </footer>
)
