import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"
import { Link } from "gatsby"
import React from "react"
import PrimaryNav from "../components/primary-nav"
import BmfLogo from "../images/bmf-creative-interiors.inline.svg"
import ButtonBookShowroom from "./button-book-showroom"
import styles from "./header.module.scss"
import ShowroomBooking from "./showroom-booking"

export default class Header extends React.Component {
  targetElement = null
  constructor(props) {
    super(props)
    this.state = {
      isMenuActive: false,
      isPopupDismissed: true,

      // isPopupDismissed: this.getCookie(this.showroomBooking) ? true : false,
    }
    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this)
    this.openShowroomBookingModal = this.openShowroomBookingModal.bind(this)
    this.closeShowroomBookingModal = this.closeShowroomBookingModal.bind(this)
  }
  showroomBooking = "showroomBooking"

  setCookie(name, value) {
    if (typeof document !== "undefined") {
      document.cookie = name + "=" + value
    }
  }

  getCookie(name) {
    if (typeof document !== "undefined") {
      const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)")
      return v ? v[2] : null
    }
  }

  deleteCookie(name) {
    this.setCookie(name, "", -1)
  }

  scrollLockBody() {
    if (!this.getCookie(this.showroomBooking)) {
      disableBodyScroll()
    }
  }

  openShowroomBookingModal() {
    this.deleteCookie(this.showroomBooking)
    this.setState({
      isPopupDismissed: false,
    })
    disableBodyScroll()
  }

  closeShowroomBookingModal() {
    this.setCookie(this.showroomBooking, false)
    this.setState({
      isPopupDismissed: true,
    })
    enableBodyScroll()
  }

  handleMenuButtonClick() {
    this.setState(state => ({
      isMenuActive: !state.isMenuActive,
    }))
    if (this.state.isMenuActive) {
      enableBodyScroll(this.targetElement)
    } else {
      disableBodyScroll(this.targetElement)
    }
  }

  componentDidMount() {
    if (!this.getCookie(this.showroomBooking)) {
      disableBodyScroll()

      setTimeout(() => {
        this.setState({
          isPopupDismissed: false,
        })
      }, 40000)
    }
    this.scrollLockBody()
    this.targetElement = document.querySelector("#primary-nav")
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    return (
      <header className={styles.header}>
        <div className={styles.logo}>
          <Link to="/">
            <BmfLogo />
          </Link>
        </div>
        <ButtonBookShowroom
          openModal={this.openShowroomBookingModal}
        ></ButtonBookShowroom>
        <nav
          className={this.state.isMenuActive ? styles.menuActive : ``}
          id="primary-nav"
        >
          <PrimaryNav></PrimaryNav>
        </nav>
        <div
          onClick={this.handleMenuButtonClick}
          className={
            this.state.isMenuActive
              ? `${styles.hamburger} hamburger hamburger--3dx is-menu-button-active`
              : `${styles.hamburger} hamburger hamburger--3dx`
          }
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <ShowroomBooking
          closeModal={this.closeShowroomBookingModal}
          isPopupDismissed={this.state.isPopupDismissed}
        ></ShowroomBooking>
      </header>
    )
  }
}
